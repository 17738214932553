<template>
  <div id="app" :class="[!isWx ? 'pc-style' : 'wx-style']">
    <meta v-if="isWx" name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
    <router-view />
  </div>
</template>
<script>
import { isMobile, setSignUrl } from "@/utils";
import Root from '@/utils/root.js'

export default {
  extends: Root,
  data() {
    return {
      isWx: null
    }
  },
  mounted() {
    setSignUrl(this)
    this.isWx = this.isMobile()
  },
  methods: {
    isMobile
  }
}
</script>

<style scoped>
.pc-style {
  min-width: 1200px;
  margin: 0 auto;
}
</style>
