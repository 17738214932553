import enLocale from 'element-ui/lib/locale/lang/en'
export default {
    首页: 'Home Page',
    返回首页: "Return to homepage",
    _404提示: "404 prompt",
    你找的页面走丢了: "The page you were looking for got lost",
    登录: "Login",
    请使用微信扫码登录: "Please use WeChat to scan the code and log in",
    二维码获取失败: "QR code acquisition failed",
    刷新以重新获取: "Refresh to retrieve again",
    用户名:"user name",
    密码:"password",
    验证码:"Verification Code",
    已过期_请刷新:"Expired, please refresh",
    注册:"register",
    登录授权:"Login authorization",
    是否授权扫码登录:"Do you authorize QR code login",
    取消:"cancel",
    确定:"determine",
    确认密码:"Confirm password",
    邮箱:"email",
    去登录:"Go login",
    验证码不能为空:"The verification code cannot be empty",
    请输入用户名:"enter username",
    用户名长度不能超过12:"The length of the username cannot exceed 12",
    请输入密码:"Please input a password",
    提交失败_请重试:"Submission failed, please try again!",
    '两次输入密码不一致!':"The password entered twice is inconsistent!",
    请再次输入密码:"Please enter your password again",
    密码长度不能低于8位:"Password length cannot be less than 8 digits",
    邮箱格式不正确:"Email format incorrect",
    邮箱不能为空:"Email cannot be empty",
    公告:"Notice",
    邮箱填写:"Fill in email address",
    请输入邮箱:"Please enter your email address",
    确认:"confirm",
    热门服务:"Popular services",
    网站持续更新升级中_欢迎同行洽谈与对接:"The website is continuously being updated and upgraded. We welcome colleagues to negotiate and connect with us~",
    充值:"Recharge",
    当前积分:"Current points",
    批量查询工具:"Batch query tool",
    搜索服务:"Search Service",
    价格:"price",
    预计时间:"estimated time",
    请填写邮箱:"Please fill in your email address",
    邮箱提交成功:"Email submission successful",
    批量工具:"Batch tool",
    自助提现:"Self withdrawal",
    联系客服:"Contact customer service",
    自助充值:"Redeem Voucher ",
    订单记录:"Order record",
    提交订单:"place order",
    服务列表:"Service List",
    余额变更记录:"Balance change record",
    用户ID:"User ID",
    用户中心:"User Center",
    退出登录:"Log out",
    发送IMEI或序列号:"Send IMEI or serial number",
    提交方式:"Submission method",
    服务说明:"Service Description",
    拒绝:"refuse",
    完成:"complete",
    正在处理:"Processing in progress",
    等待接受:"Waiting for acceptance",
    累计充值:"Accumulated recharge",
    进行中:"have in hand",
    货币:"currency",
    点击复制:"Click to copy",
    定价组:"Pricing Group",
    地区:"region",
    选择_搜索服务:"Select/Search Services",
    如客户信息_设备编码等:"Such as customer information, device codes, etc.:",
    订单内容:"Order Content",
    扫一扫:"scan",
    订单内容输入框提示:"Enter IMEI or serial number, one line at a time\nIMEI&SN viewing method: Phone settings - General - About this phone - IMEI/serial number\n",
    订单备注:"Order remarks",
    确认提交:"Confirm submission",
    我已查看服务说明并同意其中条款:"I have reviewed the service description and agree to its terms",
    在上方选择需要下单的服务:"Select the service that needs to be ordered above",
    结果:"result",
    创建时间:"Creation time",
    更新时间:"Update time",
    状态:"state",
    服务:"service",
    排序:"sort",
    可选:"Optional",
    筛选:"screen",
    计数:"count",
    重置:"Reset",
    全选:"Select All",
    导出全部结果:"Export all results",
    订单输入:"Order input",
    订单编号:"Order ID",
    IMEI等:"IMEI...",
    开始日期:"Start date",
    结束日期:"End Date",
    选择状态:"Select Status",
    金额:"amount of money",
    操作:"operation",
    意见反馈:"Feedback",
    充值积分:"Recharge points",
    历史订单:"Historical orders",
    搜索订单:"Search for orders",
    刷新页面:"Refresh page",
    会员中心:"Member Center",
    返回主页:"Return to homepage",
    关闭页面:"Close page",
    等待支付:"Waiting for payment",
    等待用户支付:"Waiting for user payment",
    如浏览器未正确跳转请点击:"If the browser does not redirect correctly, please click",
    等待用户支付_请稍后:"Waiting for user payment, please wait a moment",
    复制成功:"复制成功",
    请使用微信扫一扫扫码支付:"Please use WeChat to scan and pay by scanning the QR code",
    请使用支付宝扫一扫扫码支付:"Please use Alipay \"Scan\" to scan the code for payment",
    支持微信_支付宝扫码:"Support WeChat and Alipay code scanning",
    请复制链接到手机端打开:"Please copy the link to the mobile app and open it",
    返回网站:"Return to website",
    提现:"Withdrawal",
    保存图片:"Save Image",
    基本信息:"essential information",
    下单时间:"Order time ",
    订单状态:"Order status",
    生成图片:"Generate images",
    消耗积分:"Consume points",
    订单结果:"Order results",
    已反馈:"Feedback received",
    反馈异常:"Abnormal feedback",
    搜索:"Search",
    筛选状态:"Filter status",
    筛选服务:"Filtering service",
    一行一个:"One line, one",
    记录id:"Record ID",
    说明:"explain",
    服务名称:"Service Name",
    变更金额:"Change amount",
    剩余金额:"Remaining amount",
    支付宝账户:"Alipay account",
    支付宝账户实名:"Real name of Alipay account",
    请输入支付宝账户实名:"Please enter the real name of Alipay account",
    提现金额:"Withdrawal amount",
    "输入提现金额 (CNY)":"Enter withdrawal amount (CNY)",
    支付宝提现:"Alipay withdrawal",
    微信提现:"WeChat withdrawal",
    温馨提示:"reminder",
    "提现金额＜100时不支持提现，仅能用于平台消费":"When the withdrawal amount is less than 100, withdrawal is not supported and can only be used for platform consumption",
    我已查看以上说明:"I have reviewed the above instructions",
    确认提现:"Confirm withdrawal",
    请输入支付宝账户:"Please enter Alipay account",
    支付宝账户实名不能为空:"The real name of Alipay account cannot be empty",
    支付宝账户不能为空:"Alipay account cannot be empty",
    金额不能为空:"The amount cannot be empty",
    邮箱验证:"Email verification",
    请查收邮箱:"Please check your email",
    的邮件:"",
    快速选择充值金额:"Quickly select recharge amount",
    支付宝支付:"Alipay payment",
    免手续费:"Free handling fee",
    微信支付:"WeChat Pay",
    手续费:"Service Charge",
    应付:"cope with",
    积分:"integral",
    '1、充值积分＜10时不支持提现，仅能用于平台消费；':'1. When the recharge points are less than 10, withdrawal is not supported and can only be used for platform consumption;',
    '2、如需申请提现积分必须≥10，提现需1%手续费；':'2. To apply for withdrawal of points, it must be ≥ 10, and a 1% handling fee is required for withdrawal;',
    '3、充值积分≥500可联系人工客服免手续费充值。':'3. Recharge points ≥ 500 can be recharged free of charge by contacting customer service.',
    确认充值:"Confirm recharge",
    到账:"Received by account",
    处理结果:"Processing results",
    工单状态:"Work order status",
    工单内容:"Work order content",
    工单类型:"Work order type",
    发起新工单:"Initiate a new work order",
    请输入关键词:"Please enter keywords",
    提交:"Submit",
    微信号:"wechat number",
    中文:"Chinese",
    英语:"English",
    订单历史:"Order History",
    充值记录:"Recharge Record",
    每日资讯:"Daily News",
    批量查询:"Batch Query",
    等待中:"Waiting",
    已完成:"Completed",
    失败:"Fail",
    数据统计:"Data Statistics",
    最新服务:"Latest Services",
    更多:"more",
    备案信息:"Filing information",
    最近5次登录:"Last 5 logins",
    '生成批量查询API KEY':"Generate batch queries API KEY",
    修改密码:"Change your password",
    邮箱绑定:"Email binding",
    邮件设置:"Mail settings",
    API设置:"API settings",
    新密码:"New passwords",
    重复新密码:"Repeat the new password",
    请输入新密码:"Please enter a new password",
    再输入一次:"Enter again",
    安全邮箱:"Secure mailbox",
    输入想要绑定的邮箱:"Enter the email address you want to bind to",
    已绑定第三方账号:"Already bound to a third-party account",
    微信:"WeChat",
    '如需解除第三方账号绑定，联系客服':"If you need to unbind your third-party account, please contact customer service",
    订阅服务更新邮件:"Subscription service update email",
    接受低余额提醒:"Accept low balance reminder",
    提醒金额:"Reminder amount",
    当前启用状态:"Current enabled status",
    禁用:"Disable",
    启用API功能:"Enable API functionality",
    提交更改:"Submit changes",
    余额:"balance",
    绑定邮箱:"Bind email",
    确认修改密码:"Confirm password change",
    个人中心:"Personal Center",
    修改成功:"Modified successfully",
    请输入金额:"Please enter the amount",
    请输入正确的邮箱地址:"Please enter the correct email address",
    请输入邮箱地址:"Please enter your email address",
    '包含大小写字母、数字':"Contains uppercase and lowercase letters, numbers",
    '请输入IMEI，换行可输入多个，手机端使用回车键进行换行':"Please enter IMEI, multiple line breaks can be entered, and use the Enter key on the mobile phone for line breaks",
    '如客户信息、设备码':"Such as customer information, device code",
    请选择服务:"Please choose a service",
    已选择的服务:"Selected services",
    我已查看服务说明并同意上述条款:"I have reviewed the service description and agree to the above terms",
    '设备型号以及是否打开了"查找我的iPhone";':"Device model and whether \"Find My iPhone\" is turned on;",
    '此服务不支持查询MacBook笔记本或电脑;':"This service does not support querying MacBook laptops or computers;",
    '如需查询MacBook请使用1127专用服务;':"To check MacBook, please use the 1127 dedicated service;",
    '本服务为实时服务,不支持取消或者退积分;':"This service is a real-time service and does not support canceling or refunding points;",
    '本服务返回数据仅供参考;仅为手机维修从业者提供参考;':"The data returned by this service is for reference only; Only providing reference for mobile phone repair practitioners;",
    订单提交成功:"Order submitted successfully",
    需要消耗积分:"Need to consume points",
    扫码输入:"Scan code input",
    '自定义充值金额：':"Customize recharge amount：",
    元:"yuan",
    大额充值联系管理员:"Contact the administrator for large recharge",
    '1. 本公众号为智能自动化运行不设客服或人工服务':"1. This official account does not provide customer service or manual service for intelligent automatic operation",
    '2. 我们仅为手机维修同行们提供技术支持':"2. We only provide technical support for mobile phone repair colleagues",
    '3. 充值成功后我们不支持充值积分':"3. After successful recharge, we do not support recharging points",
    退款:"refund",
    ' ，如有疑虑，请勿充值。':"，If you have any doubts, please do not recharge.",
    '请大家仔细阅读后再操作！特此声明！敬请谅解！':"Please read carefully before operating! Hereby declare! Please understand!",
    只允许输入正整数:"Only allow input of positive integers",
    请输入数字:"please enter a number",
    '服务器异常,请重试':"Server exception, please try again",
    最低可充值1元:"The minimum recharge amount is 1 yuan",
    支付宝:"Alipay",
    成功:"success",
    筛选账单:"Filter bills",
    充值金额:"Recharge amount",
    充值结果:"Recharge result",
    充值方式:"Recharge method",
    订单Id:"Order ID",
    交易号:"Transaction Number",
    输入交易号:"Enter transaction number",
    选择排序类型:"Select sorting type",
    选择筛选类型:"Select filter type",
    支付方式:"Payment method",
    选择支付方式:"Choose payment method",
    请输入充值金额:"Please enter the recharge amount",
    账单ID:"Bill ID",
    创建者:"creator",
    温馨提醒:"Kind Reminder",
    输入账单ID:"Enter billing ID",
    '暂未上线，敬请期待！':"Not yet online, please stay tuned!",
    请输入内容:"Please enter the content",
    选择文件:"Select file",
    清空内容:"Clear content",
    解析数据:"Analyze data",
    '提示：':"Tips:",
    '1、当前余额可以提交订单数量:':"1、The current balance allows for the submission of order quantities:",
    '2、当前订单数:':"2、Current number of orders:",
    '3、重复订单有:':"3、There are duplicate orders:",
    '4、重复订单会自动去重':"4、Duplicate orders will be automatically deduplicated",
    已导入:"Imported",
    个:"",
    序号:"Serial number",
    总数:"total",
    导出到xlsx:"Export to xlsx",
    '选择导出的列:':"Select exported columns:",
    '导出文件名:':"Export file name:",
    请输入文件名:"",
    '导入IMEI/SN':"导入IMEI/SN",
    客服联系方式:"客服联系方式",
    数量:"数量",
    开始:"Start",
    暂停:"Pause",
    刷新:"Refresh",
    导入:"Import",
    导出:"Export",
    导出最近提交订单:"Export recently submitted orders",
    重置失败:"Reset failed",
    清空:"Clear",
    请输入订单内容:"Please enter the order details",
    导出订单:"Export order",
    导出订单失败:"Export order failed",
    总共为您找到:"We have found a total for you",
    条订单数据:"Order data",
    复制结果:"Copy results",
    提交时间:"Submission time",
    暂无数据:"No data available at the moment",
    输入新密码:"Enter new password",
    创建时间降序:"Create time in descending order",
    创建时间升序:"Create time in ascending order",
    更新时间降序:"Update time in descending order",
    更新时间升序:"Update time in ascending order",
    订单金额降序:"Order amount in descending order",
    订单金额升序:"Order amount in ascending order",
    输入结果:"Input results",

    ...enLocale,
}