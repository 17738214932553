<template>
  <div>
    <el-dialog :visible="visible" width="320px" style="border-radius: 10px;overflow: hidden;">
      <div class="cont" :style="{ backgroundImage: `url(${bgUrl})` }">
        <div class="t2">{{ text }}</div>
        <div v-if="type === 's' || type === 'f'" class="t3" :style="{ backgroundColor: color }"
          @click="$store.state.showStatus = ''">{{$t('确认')}}</div>
        <div class="t4" v-if="type === 'u'">
          <div class="btn" :style="{ backgroundColor: color }" @click="$store.state.showStatus = ''">{{$t('确认')}}</div>
          <div class="btn" :style="{ backgroundColor: color }" @click="$store.state.showStatus = ''">{{$t('联系客服')}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import s from '@/assets/images/success_03.png'
import f from '@/assets/images/faild_03.png'
import u from '@/assets/images/undefined_03.png'
export default {
  data() {
    return {

    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
  },
  computed: {
    visible() {
      return this.type !== ''
    },
    bgUrl() {
      if (this.type === 's') {
        return s
      } else if (this.type === 'f') {
        return f
      } else if (this.type === 'u') {
        return u
      } else {
        return ''
      }
    },
    color() {
      if (this.type === 's') {
        return '#75D894'
      } else if (this.type === 'f') {
        return '#EB4B46'
      } else {
        return '#5F86E1'
      }
    },
    text() {
      if (this.type === 's') {
        return '操作成功！'
      } else if (this.type === 'f') {
        return '抱歉，操作失败！'
      } else if (this.type === 'u') {
        return '抱歉，发生未知错误！'
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  min-height: 100px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-bottom: .3rem;
  padding-top: 200px;

  .t4 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    gap: 20px;

    .btn {
      height: .4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: .5rem;
      flex: 1;
    }
  }

  .t2 {
    font-size: .2rem;
    color: #333;
    text-align: center;
    padding-bottom: .2rem;
  }

  .t3 {
    width: 90%;
    height: .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 auto;
    border-radius: .5rem;
  }


}

::v-deep .el-dialog {
  border-radius: 10px;
  overflow: hidden;
}

::v-deep .el-dialog__body {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__footer {
  display: none;
}
</style>