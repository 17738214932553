<template>
  <div class="footer">
    <div class="w1200">
      <div class="cont">
        <div class="img">
          <img src="@/assets/images/qccode.jpg" alt="">
        </div>
        <div class="text">
          <div class="nav">
            <div class="item" v-for="(item, index) in navList" :key="index" @click="$router.push(item.url)">{{
              $t(item.name)
              }}
            </div>
          </div>
          <div class="ba">{{ $t('备案信息') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  data() {
    return {

    };
  },
  props: {
    navList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {

    handleSelect(index, indexPath) {
      console.log(index, indexPath)
    }
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #f6f7f8;
  padding: 40px 0;

  .cont {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img {
      width: 120px;
      height: 120px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      flex: 1;
      padding-left: 50px;

      .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          font-size: 16px;
          color: #333;
          cursor: pointer;
        }
      }

      .ba {
        font-size: 16px;
        text-align: center;
        color: #666;
        margin-top: 20px;
      }
    }
  }
}
</style>
