<template>
  <div class="nav-bar">
    <div class="w1200">
      <el-menu
        background-color="#ffffff"
        text-color="#495057"
        mode="horizontal"
        active-text-color="#3B82F6"
        :default-active="$route.path"
        class="nav"
        router
        @select="handleSelect"
      >
        <el-menu-item
          v-for="(item, index) in navList"
          :key="index"
          :index="item.url"
        >
          {{ $t(item.name) }}
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    navList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    // navList() {
    //     return [
    // {
    //     name: this.$t('服务列表'),
    //     icon: "el-icon-notebook-1",
    //     url: "/service",
    // },
    // {
    //     name: this.$t('提交订单'),
    //     icon: "el-icon-sell",
    //     url: "/create",
    // },
    // {
    //     name: this.$t('订单记录'),
    //     icon: "el-icon-shopping-cart-2",
    //     url: "/order-log",
    // },
    // {
    //     name: this.$t('自助充值'),
    //     icon: "el-icon-bank-card",
    //     url: "/charge",
    // },
    // {
    //     name: this.$t('自助提现'),
    //     icon: "el-icon-bank-card",
    //     url: "/withdrawal",
    // },
    // {
    //     name: this.$t('联系客服'),
    //     icon: "el-icon-message",
    //     url: "/contact",
    // },
    // {
    //     name: "工单列表",
    //     icon: "el-icon-document-copy",
    //     url: "/work-list",
    // },
    // {
    //     name: "账单列表",
    //     icon: "el-icon-money",
    //     url: "",
    // },
    // {
    //     name: this.$t('余额变更记录'),
    //     icon: "el-icon-s-operation",
    //     url: "/points-log",
    // },
    // {
    //     name: this.$t('批量工具'),
    //     icon: "el-icon-s-cooperation",
    //     url: "/batchQueryTool",
    // },
    // {
    //     name: "批量查询工具",
    //     icon: "el-icon-link",
    //     url: "",
    // },
    //     ];
    // }
  },
  methods: {
    handleSelect(index, indexPath) {
      console.log(index, indexPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  background-color: #fff;
  border-bottom: solid 1px #e6e6e6;
}

::v-deep .el-menu {
  display: flex;
  justify-content: space-between;

  &:after {
    display: none;
  }

  &:before {
    display: none;
  }
}

::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}
::v-deep .el-menu-item {
  font-weight: bold;
}
</style>
