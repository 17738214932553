<template>
  <div style="position: relative; z-index: 1">
    <!-- <div class="title" :style="{ 'padding': path === '/home' ? '.15rem 0 0' : '.15rem 0' }">官解有渠道</div> -->
    <div
      class="user-info container-m"
      :style="{
        'background-color':
          path !== '/home' ? 'rgba(82,136,232,.6)' : 'transparent',
      }"
    >
      <div class="info">
        <div class="avator">
          <img :src="getUserInfo.headimgurl" alt="" />
        </div>
        <div class="text">
          <div class="name">
            <div class="t1">{{ getUserInfo.userName }}</div>
            <img src="@/assets/images/vip_03.png" alt="" />
          </div>
          <div class="money">{{$t('余额')}}：¥{{ getUserInfo.pointsBalance }}</div>
        </div>
      </div>
      <div class="btn-list" v-if="path !== '/home'">
        <div class="btn lx" @click="$router.push('/contact')">{{$t('联系客服')}}</div>
        <div class="btn zy" @click="$router.push('/home')">{{$t('返回主页')}}</div>
      </div>
      <div class="btn-list" v-if="path === '/home'" style="padding-top: 0.2rem">
        <div class="btn jf" @click="$router.push('/charge')">{{$t('充值积分')}}</div>
        <div class="btn lx" @click="$router.push('/order-log')">{{$t('历史订单')}}</div>
        <div class="btn zy" @click="$router.push('/user-info')">{{$t('会员中心')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import {getUser, replace, hasEnt} from "@/utils";
// import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      user: null,
      path: "",
    };
  },
  watch: {
    $route(to) {
      /* 监听路由变化 */
      this.changeHeader(to.path);
    },
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  mounted() {
    /* 页面刷新, 重新构建header */
    //
    this.path = this.$route.path;
    // console.log(this.$route,this.$router)
  },
  created() {},
  methods: {
    changeHeader(path) {
      this.path = path;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 0.2rem;

  text-align: center;
}

.user-info {
  display: flex;
  align-items: flex-start;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  justify-content: space-between;

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .avator {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      padding-left: 0.1rem;

      .name {
        display: flex;
        align-items: center;

        .t1 {
          font-size: 0.16rem;
          color: #333;
        }

        img {
          width: 0.4rem;
          margin-left: 0.05rem;
        }
      }

      .money {
        font-size: 0.14rem;
        color: #333;
        margin-top: 0.01rem;
      }
    }
  }

  .btn-list {
    flex-shrink: 0;
    padding-top: 0.1rem;
    display: flex;
    align-items: center;
    font-size: 0.12rem;

    .btn {
      padding: 0.02rem 0.04rem;
      color: #fff;
      border-radius: 4px;
      margin-left: 0.1rem;

      &.lx {
        background-image: linear-gradient(to right, #31b84c, #2c9b57);
      }

      &.zy {
        background-image: linear-gradient(to right, #cc6474, #b7467d);
      }

      &.jf {
        background-image: linear-gradient(to right, #74a4fe, #7d89f1);
      }
    }
  }
}
</style>
