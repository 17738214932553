<template>
  <div v-if="!isMobile">
    <HeaderBar @refreshComponent = "handleRefreshComponent"/>
    <div class="container">
      <NavBar :navList="navList" />
      <router-view :key="componentKey"/>
      <Footer :navList="navList" />
    </div>
  </div>
  <div v-else style="position:relative;">
    <HeaderWx />
    <router-view />
    <DialogMobile :type="$store.state.showStatus" />
  </div>
</template>

<script>
import NavBar from "@/components/home/NavBar.vue";
import HeaderBar from "@/components/home/HeaderBar.vue";
import Footer from "@/components/home/Footer.vue";
import HeaderWx from "@/components/home/HeaderWx.vue";
import { isMobile } from "@/utils";
import DialogMobile from "@/components/home/DialogMobile.vue";
// import apiService from '@/api/apiService';
// import appConfig from "@/config/appConfig";
// import storage from "store";

export default {
  name: "HomeView",
  components: {
    HeaderWx,
    NavBar,
    HeaderBar,
    Footer,
    DialogMobile
  },
  data() {
    return {
      componentKey:0,
      icon: "el-icon-s-unfold",
      isApp: window.innerWidth <= 991,
      isMobile: false,
      showAside: true,
      asideStatus: true,
      navList: [
        {
          name: '首页',
          url: "/home",
        },
        {
          name: '会员中心',
          url: "/user-info",
        },
        {
          name: '服务列表',
          url: "/service",
        },
        {
          name: '订单历史',
          url: "/order-log",
        },
        {
          name: '充值记录',
          url: "/charge",
        },
        {
          name: '每日资讯',
          url: "/news",
        },
        {
          name: '批量查询',
          url: "/batchQueryTool",
        },
      ],
    };
  },
  mounted() {
    // apiService.get('/currentUserInfo').then(res=>{
    //     if (res.code === 0){
    //         storage.set(appConfig.APP_USER,res.data)
    //     }else {
    //         storage.set(appConfig.ACCESS_TOKEN_NAME,null)
    //     }

    // }).catch(() => {})
    window.addEventListener("resize", this.handleResize);
    /* 屏幕过小, 刚加载时不显示侧边栏 */
    // this.appCollapse();
    this.isMobile = isMobile();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleRefreshComponent(language){
      localStorage.setItem("language", language);
      this.componentKey++;
      this.$i18n.locale = language; //这个代码负责实时切换语言
    },
    handleResize() {
      this.isApp = window.innerWidth <= 991;
      this.isMobile = isMobile();
    },
    // appCollapse() {
    //   if (this.isApp) {
    //     this.collapse();
    //   }
    // }
  },
};
</script>
