<template>
  <div class="header-bar">
    <div class="w1200 container disf alc jcs">
      <div class="logo csp">
        <img src="@/assets/images/logo.png" alt="" style="width: 160px" />
      </div>

      <div class="user">
        <el-dropdown @command="handEvent" style="margin-right: 10px;">
          <span class="el-dropdown-link">
            {{ $t(language) }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="beforeHandleCommand('zh', '中文')">{{ $t('中文') }}</el-dropdown-item>
            <el-dropdown-item :command="beforeHandleCommand('en', '英语')">{{ $t('英语') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div v-if="login">
          <el-button type="primary" size="small" @click="loginUser()">{{ $t('登录') }}</el-button>
        </div>
        <div v-if="!login" class="user-info">
          <div class="money">
            <img src="@/assets/images/money_03.png" alt="" />
            <span>{{ getUserInfo.pointsBalance }}</span>
          </div>
          <div class="topup" @click="$router.push('charge')">{{ $t('充值') }}</div>
          <el-popover class="item" placement="bottom" width="80px">
            <div class="avator" slot="reference">
              <img :src="getUserInfo.headimgurl" alt="" />
            </div>
            <div class="avator-content">
              <div class="item" @click="replace('user-info')">{{ $t('个人中心') }}</div>
              <div class="item" @click="loginOut()">{{ $t('退出登录') }}</div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { replace, loginOutUser } from "@/utils";
import { mapGetters } from "vuex";
import apiService from "@/api/apiService";
import storage from "store";
import appConfig from "@/config/appConfig";

export default {
  props: {
    icon: {
      type: String,
      default: "el-icon-s-unfold",
    },
  },
  options: { styleIsolation: "shared" },
  data() {
    return {
      language: '中文',
      user: null,
      login: true,
      searchNo: "",
      userBaseInfo: {
        userId: "USER1243",
        amount: 0,
      },
    };
  },
  created() {
    const language = localStorage.getItem('language')
    if (language === 'zh'){
      this.language = '中文'
    }else {
      this.language = '英语'
    }
    // getCurrentUser('header')
    this.user = storage.get(appConfig.ACCESS_TOKEN_NAME);
    if (this.user) {
      this.login = false;
    } else {
      this.login = true;
      // loginOutUser();
    }
  },
  computed: {
    ...mapGetters(["getToken", "getUserInfo"]),
    id() {
      return this.$store.state.id;
    },
    roleName() {
      return this.$store.state.roleName;
    },
    pointsBalance() {
      return this.$store.state.point;
    },
  },
  methods: {
    replace,
    beforeHandleCommand(item, name) {
      return {
        'command': item,
        'name': name
      }
    },
    handEvent(command) {
      this.language = command.name
      this.$emit("refreshComponent",command.command)
    },

    loginOut() {
      this.$nextTick(function () {
        apiService.get("/login-out", {}).then(() => {
          loginOutUser();
          this.login = true;
        });
      });
    },
    loginUser() {
      loginOutUser();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  height: 60px;
  border-bottom: 1px solid #eee;

  .container {
    height: 100%;

    .user {
      display: flex;
      align-items: center;
    }

    .user-info {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      .money {
        height: 100%;
        border-radius: 6px;
        padding: 0 25px 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(to right, #f7edd7, #ead097);

        img {
          width: 20px;
        }

        span {
          color: #bf8d5e;
          font-size: 18px;
          margin-left: 10px;
        }
      }

      .topup {
        height: 100%;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #fff;
        font-size: 18px;
        padding: 0 10px;
        margin: 0 10px;
        background-image: linear-gradient(to bottom, #ff944c, #ff6629);
      }

      .avator {
        height: 100%;
        width: 42px;
        border-radius: 6px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.avator-content {
  .item {
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
  }
}

::v-deep .el-popover {
  min-width: 100px;
  width: 100px;
}
</style>
