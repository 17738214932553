import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import zh from './langs/zh_CN/zh_CN'
import en from './langs/en/en_US'

Vue.use(VueI18n)

let i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'zh',
    messages: {
        'zh': {
            ...zh
        },
        'en': {
            ...en
        }
    },
    silentTranslationWarn: true
})
locale.i18n((key, value) => i18n.t(key, value))
export default i18n;