import Vue from "vue";
import Vuex from "vuex";
import apiService from "@/api/apiService";
import avator from "@/assets/images/avator_03.jpg";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showOrderSearchDialog: false,
    showWorkOrderDialog: false,
    id: "",
    roleName: "",
    advancedUser: "",
    email: "",
    point: 0,
    appTitle: "U4快查",
    token: "",
    signUrl: "",
    userInfo: "",
    orderInfo: "",
    showStatus: "",
  },
  getters: {
    getSignUrl(state) {
      return state.signUrl;
    },
    getAppTitle(state) {
      return state.appTitle;
    },
    getShowOrderSearchDialog(state) {
      return state.showOrderSearchDialog;
    },
    getShowWorkOrderDialog(state) {
      return state.showWorkOrderDialog;
    },
    getId(state) {
      return state.id;
    },
    getPoint(state) {
      return state.point;
    },
    getAdvancedUser(state) {
      return state.advancedUser;
    },
    getEmail(state) {
      return state.email;
    },
    getToken(state) {
      return state.token;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getOrderInfo(state) {
      return state.orderInfo;
    },
  },
  mutations: {
    setSignUrl(state, value) {
      state.signUrl = value;
    },
    setAppTitle(state, show) {
      state.appTitle = show;
    },
    setShowOrderSearchDialog(state, show) {
      state.showOrderSearchDialog = show;
    },
    setShowWorkOrderDialog(state, show) {
      state.showWorkOrderDialog = show;
    },
    setId(state, show) {
      state.id = show;
    },
    setPoint(state, show) {
      state.point = show;
    },
    setRoleName(state, show) {
      state.roleName = show;
    },
    setAdvancedUser(state, show) {
      state.advancedUser = show;
    },
    setEmail(state, show) {
      state.email = show;
    },
    setUserInfo(state, val) {
      state.userInfo = val;
    },
    setOrderInfo(state, val) {
      state.orderInfo = val;
    },
    setShowModal(state, val) {
      state.showStatus = val;
    },
  },
  actions: {
    getCurrentUserInfo(context) {
      apiService
        .get("/currentUserInfo")
        .then((res) => {
          if (!res.data.headimgurl) {
            res.data.headimgurl = avator;
          }
          context.commit("setUserInfo", res.data);
        })
        .catch(() => {});
    },
  },
  modules: {},
});
