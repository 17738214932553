import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
    首页: '首页',
    返回首页: '返回首页',
    _404提示: "404提示",
    你找的页面走丢了: "你找的页面走丢了",
    登录: "登录",
    请使用微信扫码登录: "请使用微信扫码登录",
    二维码获取失败: "二维码获取失败",
    刷新以重新获取: "刷新以重新获取",
    用户名:"用户名",
    密码:"密码",
    验证码:"验证码",
    已过期_请刷新:"已过期 请刷新",
    注册:"注册",
    登录授权:"登录授权",
    是否授权扫码登录:"",
    取消:"取消",
    确定:"确定",
    确认密码:"确认密码",
    邮箱:"确认密码",
    去登录:"去登录",
    验证码不能为空:"验证码不能为空",
    请输入用户名:"请输入用户名",
    用户名长度不能超过12:"用户名长度不能超过12",
    请输入密码:"请输入密码",
    提交失败_请重试:"提交失败，请重试！",
    '两次输入密码不一致!':"两次输入密码不一致!",
    请再次输入密码:"请再次输入密码",
    密码长度不能低于8位:"密码长度不能低于8位",
    邮箱格式不正确:"邮箱格式不正确",
    邮箱不能为空:"邮箱不能为空",
    公告:"公告",
    邮箱填写:"邮箱填写",
    请输入邮箱:"请输入邮箱",
    确认:"确认",
    热门服务:"热门服务",
    网站持续更新升级中_欢迎同行洽谈与对接:"网站持续更新升级中，欢迎同行洽谈与对接~",
    充值:"充值",
    当前积分:"当前积分",
    批量查询工具:"批量查询工具",
    搜索服务:"搜索服务",
    价格:"价格",
    预计时间:"预计时间",
    请填写邮箱:"请填写邮箱",
    邮箱提交成功:"邮箱提交成功",
    批量工具:"批量工具",
    自助提现:"自助提现",
    联系客服:"联系客服",
    自助充值:"自助充值",
    订单记录:"订单记录",
    提交订单:"提交订单",
    服务列表:"服务列表",
    余额变更记录:"余额变更记录",
    用户ID:"用户ID",
    用户中心:"用户中心",
    退出登录:"退出登录",
    发送IMEI或序列号:"发送IMEI或序列号",
    提交方式:"提交方式",
    服务说明:"服务说明",
    拒绝:"拒绝",
    完成:"完成",
    正在处理:"正在处理",
    等待接受:"等待接受",
    累计充值:"累计充值",
    进行中:"进行中",
    货币:"货币",
    点击复制:"点击复制",
    定价组:"定价组",
    地区:"地区",
    选择_搜索服务:"选择/搜索服务",
    如客户信息_设备编码等:"如客户信息、设备编码等:",
    订单内容:"订单内容",
    扫一扫:"扫一扫",
    订单内容输入框提示:"输入IMEI或者序列号, 一行一个\nIMEI&SN查看方法：手机进设置-通用-关于本机-IMEI/序列号\n",
    订单备注:"订单备注",
    确认提交:"确认提交",
    我已查看服务说明并同意其中条款:"我已查看服务说明并同意其中条款",
    在上方选择需要下单的服务:"在上方选择需要下单的服务",
    结果:"结果",
    创建时间:"创建时间",
    更新时间:"更新时间",
    状态:"状态",
    服务:"服务",
    排序:"排序",
    可选:"可选",
    筛选:"筛选",
    计数:"计数",
    重置:"重置",
    全选:"全选",
    导出全部结果:"导出全部结果",
    订单输入:"订单输入",
    IMEI等:"IMEI等",
    开始日期:"开始日期",
    结束日期:"结束日期",
    选择状态:"选择状态",
    金额:"金额",
    操作:"操作",
    意见反馈:"意见反馈",
    充值积分:"充值积分",
    历史订单:"历史订单",
    搜索订单:"搜索订单",
    刷新页面:"刷新页面",
    会员中心:"会员中心",
    返回主页:"返回主页",
    关闭页面:"关闭页面",
    等待支付:"等待支付",
    等待用户支付:"等待用户支付",
    如浏览器未正确跳转请点击:"如浏览器未正确跳转请点击",
    等待用户支付_请稍后:"等待用户支付,请稍后",
    复制成功:"复制成功",
    请使用微信扫一扫扫码支付:"请使用微信\"扫一扫\"扫码支付",
    请使用支付宝扫一扫扫码支付:"请使用支付宝\"扫一扫\"扫码支付",
    支持微信_支付宝扫码:"支持微信、支付宝扫码",
    请复制链接到手机端打开:"请复制链接到手机端打开",
    返回网站:"返回网站",
    提现:"提现",
    保存图片:"保存图片",
    基本信息:"基本信息",
    下单时间:"下单时间",
    订单状态:"订单状态",
    生成图片:"生成图片",
    消耗积分:"消耗积分",
    订单结果:"订单结果",
    已反馈:"已反馈",
    反馈异常:"反馈异常",
    搜索:"搜索",
    筛选状态:"筛选状态",
    筛选服务:"筛选服务",
    一行一个:"一行一个",
    记录id:"记录id",
    说明:"说明",
    服务名称:"服务名称",
    订单编号:"订单编号",
    变更金额:"变更金额",
    剩余金额:"剩余金额",
    支付宝账户:"支付宝账户",
    支付宝账户实名:"支付宝账户实名",
    请输入支付宝账户实名:"请输入支付宝账户实名",
    提现金额:"提现金额",
    "输入提现金额 (CNY)":"输入提现金额",
    支付宝提现:"支付宝提现",
    微信提现:"微信提现",
    温馨提示:"温馨提示",
    "提现金额＜100时不支持提现，仅能用于平台消费":"提现金额＜100时不支持提现，仅能用于平台消费",
    我已查看以上说明:"我已查看以上说明",
    确认提现:"确认提现",
    请输入支付宝账户:"请输入支付宝账户",
    支付宝账户实名不能为空:"支付宝账户实名不能为空",
    支付宝账户不能为空:"支付宝账户不能为空",
    金额不能为空:"金额不能为空",
    邮箱验证:"邮箱验证",
    请查收邮箱:"请查收邮箱",
    的邮件:"的邮件",
    快速选择充值金额:"快速选择充值金额",
    支付宝支付:"支付宝支付",
    免手续费:"免手续费",
    微信支付:"微信支付",
    手续费:"手续费",
    应付:"应付",
    积分:"积分",
    '1、充值积分＜10时不支持提现，仅能用于平台消费；':'1、充值积分＜10时不支持提现，仅能用于平台消费；',
    '2、如需申请提现积分必须≥10，提现需1%手续费；':'2、如需申请提现积分必须≥10，提现需1%手续费；',
    '3、充值积分≥500可联系人工客服免手续费充值。':'3、充值积分≥500可联系人工客服免手续费充值。',
    确认充值:"确认充值",
    到账:"到账",
    处理结果:"处理结果",
    工单状态:"工单状态",
    工单内容:"工单内容",
    工单类型:"工单类型",
    发起新工单:"发起新工单",
    请输入关键词:"请输入关键词",
    提交:"提交",
    微信号:"微信号",
    中文:"中文",
    英语:"英语",
    订单历史:"订单历史",
    充值记录:"充值记录",
    每日资讯:"每日资讯",
    批量查询:"批量查询",
    等待中:"等待中",
    已完成:"已完成",
    失败:"失败",
    数据统计:"数据统计",
    最新服务:"最新服务",
    更多:"更多",
    备案信息:"备案信息",
    最近5次登录:"最近5次登录",
    '生成批量查询API KEY':"生成批量查询API KEY",
    修改密码:"修改密码",
    邮箱绑定:"邮箱绑定",
    邮件设置:"邮件设置",
    API设置:"API设置",
    新密码:"新密码",
    重复新密码:"重复新密码",
    请输入新密码:"请输入新密码",
    再输入一次:"再输入一次",
    安全邮箱:"安全邮箱",
    输入想要绑定的邮箱:"输入想要绑定的邮箱",
    已绑定第三方账号:"已绑定第三方账号",
    微信:"微信",
    '如需解除第三方账号绑定，联系客服':"如需解除第三方账号绑定，联系客服",
    订阅服务更新邮件:"订阅服务更新邮件",
    接受低余额提醒:"接受低余额提醒",
    提醒金额:"提醒金额",
    当前启用状态:"当前启用状态",
    禁用:"禁用",
    启用API功能:"启用API功能",
    余额:"余额",
    提交更改:"提交更改",
    绑定邮箱:"绑定邮箱",
    确认修改密码:"确认修改密码",
    个人中心:"个人中心",
    修改成功:"修改成功",
    请输入金额:"请输入金额",
    请输入正确的邮箱地址:"请输入正确的邮箱地址",
    请输入邮箱地址:"请输入邮箱地址",
    '包含大小写字母、数字':"包含大小写字母、数字",
    '请输入IMEI，换行可输入多个，手机端使用回车键进行换行':"请输入IMEI，换行可输入多个，手机端使用回车键进行换行",
    '如客户信息、设备码':"如客户信息、设备码",
    请选择服务:"请选择服务",
    已选择的服务:"已选择的服务",
    我已查看服务说明并同意上述条款:"我已查看服务说明并同意上述条款",
    '设备型号以及是否打开了"查找我的iPhone";':"设备型号以及是否打开了\"查找我的iPhone\";",
    '此服务不支持查询MacBook笔记本或电脑;':"此服务不支持查询MacBook笔记本或电脑;",
    '如需查询MacBook请使用1127专用服务;':"如需查询MacBook请使用1127专用服务;",
    '本服务为实时服务,不支持取消或者退积分;':"本服务为实时服务,不支持取消或者退积分;",
    '本服务返回数据仅供参考;仅为手机维修从业者提供参考;':"本服务返回数据仅供参考;仅为手机维修从业者提供参考;",
    订单提交成功:"订单提交成功",
    需要消耗积分:"需要消耗积分",
    扫码输入:"扫码输入",
    '自定义充值金额：':"自定义充值金额：",
    元:"元",
    大额充值联系管理员:"大额充值联系管理员",
    '1. 本公众号为智能自动化运行不设客服或人工服务':"1. 本公众号为智能自动化运行不设客服或人工服务",
    '2. 我们仅为手机维修同行们提供技术支持':"2. 我们仅为手机维修同行们提供技术支持",
    '3. 充值成功后我们不支持充值积分':"3. 充值成功后我们不支持充值积分",
    退款:"退款",
    ' ，如有疑虑，请勿充值。':"，如有疑虑，请勿充值。",
    '请大家仔细阅读后再操作！特此声明！敬请谅解！':"请大家仔细阅读后再操作！特此声明！敬请谅解！",
    只允许输入正整数:"只允许输入正整数",
    请输入数字:"请输入数字",
    '服务器异常,请重试':"服务器异常,请重试",
    最低可充值1元:"最低可充值1元",
    支付宝:"支付宝",
    成功:"成功",
    筛选账单:"筛选账单",
    充值金额:"充值金额",
    充值结果:"充值结果",
    充值方式:"充值方式",
    订单Id:"订单Id",
    交易号:"交易号",
    输入交易号:"输入交易号",
    选择排序类型:"选择排序类型",
    选择筛选类型:"选择筛选类型",
    支付方式:"支付方式",
    选择支付方式:"选择支付方式",
    请输入充值金额:"请输入充值金额",
    账单ID:"账单ID",
    创建者:"创建者",
    温馨提醒:"温馨提醒",
    输入账单ID:"输入账单ID",
    '暂未上线，敬请期待！':"暂未上线，敬请期待！",
    请输入内容:"请输入内容",
    选择文件:"选择文件",
    清空内容:"清空内容",
    解析数据:"解析数据",
    '提示：':"提示：",
    '1、当前余额可以提交订单数量:':"1、当前余额可以提交订单数量:",
    '2、当前订单数:':"2、当前订单数:",
    '3、重复订单有:':"3、重复订单有:",
    '4、重复订单会自动去重':"4、重复订单会自动去重",
    已导入:"已导入",
    个:"个",
    序号:"序号",
    总数:"总数",
    导出到xlsx:"导出到xlsx",
    '选择导出的列:':"选择导出的列:",
    '导出文件名:':"导出文件名:",
    请输入文件名:"请输入文件名",
    '导入IMEI/SN':"导入IMEI/SN",
    客服联系方式:"客服联系方式",
    数量:"数量",
    开始:"开始",
    暂停:"暂停",
    刷新:"刷新",
    导入:"导入",
    导出:"导出",
    导出最近提交订单:"导出最近提交订单",
    重置失败:"重置失败",
    清空:"清空",
    请输入订单内容:"请输入订单内容",
    导出订单:"导出订单",
    导出订单失败:"导出订单失败",
    总共为您找到:"总共为您找到",
    条订单数据:"条订单数据",
    复制结果:"复制结果",
    提交时间:"提交时间",
    暂无数据:"暂无数据",
    输入新密码:"输入新密码",
    创建时间降序:"创建时间降序",
    创建时间升序:"创建时间升序",
    更新时间降序:"更新时间降序",
    更新时间升序:"更新时间升序",
    订单金额降序:"订单金额降序",
    订单金额升序:"订单金额升序",
    输入结果:"输入结果",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    // 公告:"",
    ...zhLocale,
}