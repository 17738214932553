import { mapGetters } from "vuex";
import store from "@/store/index.js";
export default {
  name: "Root",
  data() {
    return {};
  },
  created: async function () {
    this.getUserInfo();
    // this.getOrderInfo();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    getUserInfo() {
      store.dispatch("getCurrentUserInfo");
    },
    getOrderInfo() {},
  },
};
